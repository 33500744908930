<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="编辑支付方式" :visible.sync="EditPayWayDialogVisible" 
    :close-on-click-modal="false" width="30%" @close="CloseDialog">
        <el-form ref="PayWayFormRef" :model="PayWayForm" :rules="PayWayFormRules" 
        label-width="80px" v-loading="Loading">
            <el-form-item label="名称：" prop="Name">
                <el-input  placeholder="名称" v-model="PayWayForm.Name"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog" :disabled="Loading">取消</el-button>
            <el-button type="primary" @click="EditPayWay" :disabled="Loading">确认</el-button>
        </span>
    </el-dialog>    
</template> 

<script>
import { PutPayWay } from '@/assets/js/api/paywayapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        paywaydata:Object,
    },
    computed:{
        EditPayWayDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            PayWayForm:{ID:0,Name:''},
            PayWayFormRules:{
                Name: [
                    { required: true, message: '请输入支付方式名称', trigger: 'blur' }
                ],
            },
            Loading:false,
            DataChangeTag:false,
        }
    },
    watch:{
        EditPayWayDialogVisible:function (newVisible) {
            if(newVisible){
                this.PayWayForm.ID = this.paywaydata.id
                this.PayWayForm.Name = this.paywaydata.name
                this.DataChangeTag = false
            }
        }
    },
    methods:{
        CloseDialog(){
            this.EditPayWayDialogVisible = false
        },
        async EditPayWay(){
            if(this.PayWayForm.Name == this.paywaydata.name){
                this.$message.warning('数据没有修改，不需要提交');
                return
            }
            this.$refs.PayWayFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }

            const confirmResult = await this.$confirm('确认操作是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }

            this.Loading = true
            var ret = await PutPayWay(this.PayWayForm)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改支付方式成功');
                this.$emit("ChangeEditPayWay", ret.data)
                this.CloseDialog()
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
</style>