<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入支付方式名称" v-model="QueryInfo.Name" clearable @clear="QueryInitPayWayList"> 
                        <el-button slot="append" icon="el-icon-search" @click="QueryInitPayWayList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="AddPayWayDialogVisible = true">新建支付方式</el-button>
                </el-col>
            </el-row>
            <el-table :data="PayWayList" v-loading="Loading" @row-click="ShowEditDialog" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="DeleteSelectPayWay(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="PayWayTotal">
            </el-pagination>
            <AddPayWayDialog :visible.sync="AddPayWayDialogVisible"
            v-on:ChangeAddPayWay='ParentChangeAddPayWay($event)'>
            </AddPayWayDialog>

            <EditPayWayDialog :visible.sync="EditPayWayDialogVisible" :paywaydata="PayWayData"
            v-on:ChangeEditPayWay='ParentChangeEditPayWay($event)'>
            </EditPayWayDialog>
        </el-card>
      
    </div>
</template>


<script>
import { GetPayWayList, DeletePayWay } from '@/assets/js/api/paywayapi'
import AddPayWayDialog from '../Dialog/addpaywaydialog.vue';
import EditPayWayDialog from '../Dialog/editpaywaydialog.vue';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    components:{
        AddPayWayDialog,
        EditPayWayDialog,
    },
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Start:1,
                Count:SpreadCount[0],
            },
            PayWayList:[],
            PayWayTotal:0,
            Loading:false,
            AddPayWayDialogVisible:false,
            EditPayWayDialogVisible:false,
            PayWayData:{},
        }
    },
    created(){
        this.QueryPayWayList()
    },
    methods:{
        QueryInitPayWayList(){
            this.QueryInfo.Start = 1;
            this.QueryInfo.Count = this.spreadCount[0];
            this.QueryPayWayList()
        },
        async QueryPayWayList(){
            this.Loading = true
            var ret = await GetPayWayList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.PayWayList = ret.data.payways
                this.PayWayTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryPayWayList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryPayWayList()
        },
        ParentChangeAddPayWay(payway){
            this.PayWayList.unshift(payway)
            if(this.PayWayList.length > this.QueryInfo.Count)
            {
                this.PayWayList.pop()
            }
            this.PayWayTotal += 1
        },
        ParentChangeEditPayWay(payway){
            this.PayWayList.forEach(item=>{
                if(item.id == payway.id){
                    item.name = payway.name
                }
            })
        },
        ShowEditDialog(payway){
            this.PayWayData = payway
            this.EditPayWayDialogVisible = true
        },
        async DeleteSelectPayWay(payway){
            const confirmResult = await this.$confirm('确认删除支付方式 ' + payway.name +' ，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.Loading = true
            var ret = await DeletePayWay(payway.id)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除支付方式成功');
                this.QueryPayWayList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>