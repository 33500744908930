import axios from 'axios';

//查询支付方式
export async function GetPayWayList(queryinfo){
    var res = await axios.get('PayWay/v1/PayWaysQuery', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //提交添加支付方式信息
  export async function PostPayWay(paywayinfo){
    var res = await axios.post('PayWay/v1/PayWay', paywayinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  //提交修改支付方式信息
  export async function PutPayWay(paywayinfo){
    var res = await axios.put('PayWay/v1/EditPayWay', paywayinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //根据ID删除支付方式
  export async function DeletePayWay(id){
    var res = await axios.delete('PayWay/v1/PayWay',{ params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

//名单获取途径
export async function GetALlPayWayList(){
  var res = await axios.get('PayWay/v1/AllPayWay')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}